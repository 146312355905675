


const About = ({title, text, src, career}) => {
    return (
        <section id="about" className={career === "yes" ? "flex bg-black" : "flex"}>
            <div className="flex container flex-column">
                <div className="hero-container flex-grow">
                    <div className="flex-grow hero-mobile">
                        <div className="hero-column c1 relative" data-aos="fade-right" data-aos-delay="50">
                            {career === "yes" ? <span className="about-span"></span> : <></> }
                            <h1 className={career === "yes" ? "white-text" : "black-text"}>{title}</h1>
                            <p className={career === "yes" ? "white-text" : "black-text"}>{text}</p>
                            {career === "yes" ? <></> : <a href="/karriere" className="dark-btn">Karriere</a> }
                        </div>
                        <div className="hero-column c2 relative" data-aos="fade-left" data-aos-delay="50">
                            <img alt="section bild" src={src}></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;