

const Steps = (props) => {
    return (
        <section id="sponsoren" className={`${ props.styleName }`}>
            <div className="container flex flex-column">
                <h3 className="color-header">PARTNER</h3>
                <h2>von Uns betreute Vereine</h2>
                <div className="flex sponsor-container">
                    <div className="sponsor-item flex flex-center">
                        <a href="https://www.tsv-otterndorf.de/"><img alt="sponsoren bild" src="/img/sponsor-2.png" /></a>
                    </div>
                    <div className="sponsor-item flex flex-center">
                        <a href="https://www.vfl-07.de/"><img alt="sponsoren bild" src="/img/sponsor-3.png" /></a>
                    </div>
                    <div className="sponsor-item flex flex-center">
                        <a href="http://www.fc08hassloch.de/"><img alt="sponsoren bild" src="/img/sponsor-4.png" /></a>
                    </div>
                    <div className="sponsor-item flex flex-center">
                        <a href="https://www.haie.de/"><img alt="sponsoren bild" src="/img/sponsor-5.png" /></a>
                    </div>
                    <div className="sponsor-item flex flex-center">
                        <a href="https://psvtrier.de/"><img alt="sponsoren bild" src="/img/sponsor-6.png" /></a>
                    </div>
                </div>
            </div>
        </section>
    )
}   

export default Steps;