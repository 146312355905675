
import Hero from '../components/Hero';
import Werbemittel from '../components/Werbemittel';
import Helmet from 'react-helmet';
import KontaktSection from '../components/KontaktSection';

const Sponsoren = (props) => {
    return (
        <div>
            <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="Sponoring, lokale Werbung, lokale Unterstützung, Friedrichsdorf, Deutschland" />
                <title>SPH - lokales Sponsoring</title>
                <meta name="description" content="Lokales Sponsoring von Vereien. Zielgerichtete Out-Of-Home Werbung kombiniert mit sozialer Förderung ihrer Region."/>
            </Helmet>
            <Hero goTo="vorteile" src={'/img/sponsoren.png'} title="" filler="Lokales" title2="Sponsoring" text="Zielgerichtete Out-Of-Home Werbung kombiniert mit sozialer Förderung ihrer Region."></Hero>
            <section id="vorteile">
                <div className="container flex">
                    <div className="c1 hero-column">
                        <h4 className="color-header" data-aos="fade-up" data-aos-delay="0">PERFORMANCE</h4>
                        <h1 className="big-header black-text" data-aos="fade-up" data-aos-delay="100">Ihre Vorteile</h1>
                        <div className="vorteile">
                            <p data-aos="fade-up" data-aos-delay="150"><span></span>Kostengünstige Werbung im Out-Of-Home Media Bereich (Tausenderkontaktpreis(TKP) 4x günstiger als bei Printanzeigen)</p>
                            <p data-aos="fade-up" data-aos-delay="200"><span></span>Regionale Zielgruppe</p>
                            <p data-aos="fade-up" data-aos-delay="250"><span></span>Optimierte Außendarstellung und Mitarbeiter Marketing</p>
                            <p data-aos="fade-up" data-aos-delay="300"><span></span>Förderung von sozialen und lokalen Einrichtungen</p>
                            <p data-aos="fade-up" data-aos-delay="350"><span></span>Image Vorteil</p>
                        </div>
                    </div>
                    <div className="c2 hero-column">
                        <img alt="charts icon" src="/img/charts.png"></img>
                    </div>
                </div>
            </section>
            <Werbemittel></Werbemittel>
            <KontaktSection></KontaktSection>
        </div>
    );
}

export default Sponsoren;