
import Text from '../components/Text';
import Helmet from 'react-helmet';


const Impressum = (props) => {
    return (
        <div>
            <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="" />
                <title>Impressum - SPH</title>
                <meta name="description" content="Hier finden Sie unser Impressum, sowie aktuelle Kontaktdaten von SPH."/>
            </Helmet>
            <section id="hero" className="impressum">
                <div className="container flex hero-mobile">
                    <div className="impressum-column">
                        <h4 className="color-header">SPH Media GmbH</h4>
                        <h1 className="big-header white-text">Impressum</h1>
                        <p className="white-text">Max-Planck-Straße 9, 61381 Friedrichsdorf</p>
                    </div>
                </div>
                
            </section>
            <div className="container flex">
                
            </div>
            <div className="container flex flex-column text-left m-t-100 m-b-100 rechtliche-texte">
                <div className="flex flex-column">
                    <p>Angaben gemäß § 5 TMG<br></br><br></br>SPH Media GmbH<br></br>Max-Planck-Straße 9<br></br>61381 Friedrichsdorf<br></br><br></br>Vertreten durch:<br></br>Christopher Hofmann<br></br><br></br><br></br><br></br>Kontakt:<br></br>Telefon: +49 (0)6172 59070<br></br>Fax: +49 (0)6172 590720<br></br>E-Mail: sph@sport-info.de<br></br>Website: sport-info.de<br></br><br></br>Registereintrag:<br></br>Eintragung im Registergericht: Bad Homburg<br></br>Registernummer: HRB 4396<br></br><br></br>Steuernummer:<br></br>00324776866<br></br><br></br>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br></br>Christopher Hofmann<br></br>Max-Planck-Straße 9<br></br>61381 Friedrichsdorf<br></br><br></br>Design & Umsetzung<br></br>clickb8 Webentwicklung & Medienagentur<br></br><a className="color-header" href="www.clickb8.de">www.clickb8.de</a>

                    </p>
                    <h2>Haftungsausschluss</h2>
                </div>
                <Text title="HAFTUNG FÜR INHALTE" textContent="Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."></Text>
                <Text title="HAFTUNG FÜR LINKS" textContent="Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."></Text>
                <Text title="URHEBERRECHT" textContent="Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen."></Text>
                <p>Photo by <a href="https://unsplash.com/@arnok?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Moises Alex</a> on <a href="https://unsplash.com/s/photos/tennis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                <p>Photo by <a href="https://unsplash.com/@jeffreyflin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jeffrey F Lin</a> on <a href="https://unsplash.com/s/photos/soccer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                <p>Photo by <a href="https://unsplash.com/@d_mccullough?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniel McCullough</a> on <a href="https://unsplash.com/s/photos/basketball?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                <p>Photo by <a href="https://unsplash.com/@proxyclick?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Proxyclick Visitor Management System</a> on <a href="https://unsplash.com/s/photos/company?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                <div>Icons made by <a href="https://www.flaticon.com/" title="Those Icons">Those Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://smashicons.com/" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://www.flaticon.com/authors/wanicon" title="wanicon">wanicon</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons erstellt von <a href="https://creativemarket.com/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons erstellt von <a href="https://creativemarket.com/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://www.flaticon.com/authors/xnimrodx" title="xnimrodx">xnimrodx</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://www.flaticon.com/authors/nikita-golubev" title="Nikita Golubev">Nikita Golubev</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://www.flaticon.com/authors/smartline" title="Smartline">Smartline</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <div>Icons made by <a href="https://roundicons.com/" title="Roundicons">Roundicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </div>
        </div>
        
            
    );
}

export default Impressum;