
import Hero from '../components/Hero';
import About from '../components/About';
import Helmet from 'react-helmet';

const Karriere = (props) => {
    return (
        <div>
            <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="karriere, sph, Außendienst Mitarbeiter, Handelsvertreter, Vertriebsteam, Verstärkung" />
                <title>Karriere bei SPH, helfen Sie dabei Vereine finanziell zu unterstützen.</title>
                <meta name="description" content="Seit über 50 Jahren sind wir zuverlässiger Partner für zahlreiche Außendienst Mitarbeiter & freie Handelsvertreter und sind auf der Suche nach Verstärkung für unser Vertriebsteam."/>
            </Helmet>
            <Hero goTo="job" src={'/img/career.png'} title="" filler="Karriere" title2="bei SPH" text="Seit über 50 Jahren sind wir zuverlässiger Partner für zahlreiche Außendienst Mitarbeiter & freie Handelsvertreter. Wir sind stets auf der Suche nach Verstärkung für unser Vertriebsteam."></Hero>
            <section id="job">
                <div className="container flex flex-column">
                    <h3 className="color-header">OFFENE STELLEN</h3>
                    <h4>Freier Handelsvertreter (m / w / d) bundesweit - Out-of-Home-Media Sales - Voll- oder Teilzeit</h4>
                    <div className="flex flex-row job-container">
                        <div>
                            <h4><strong>Was wir bieten</strong></h4>
                            <p><span></span>Ein starkes, erfolgreiches Unternehmen mit langjährigem Kundenstamm</p>
                            <p><span></span>Sehr attraktive Verdienstmöglichkeiten auf Provisionsbasis</p>
                            <p><span></span>Zielgerichtete Einarbeitung & Training</p>
                            <p><span></span>eigenes KFZ</p>
                            <p><span></span>Freie Zeiteinteilung</p>
                        </div>
                        <div>
                            <h4><strong>Was wir suchen</strong></h4>
                            <p><span></span>Verhandlungsgeschick und Verkaufsstärke</p>
                            <p><span></span>Seriosität im Umgang mit Geschäftskunden</p>
                            <p><span></span>Kaufmännisches Denken und Handeln</p>
                            <p><span></span>Selbstständiges Arbeiten</p>
                        </div>
                    </div>
                    <a className="dark-btn display-block" href="/kontakt">Jetzt bewerben</a>
                </div>
            </section>
            <About src="/img/about.jpg"  career="yes" title="Wer sind wir?" text="Bereits seit über 50 Jahren sind wir ein zuverlässiger Partner für Vereine, Sponsoren und Außendienstmitarbeiter und haben in dieser Zeit über 6000 Vereine in Deutschland vermarktet und damit finanziell unterstützt. Wir vermakrten bereits eine Vielzahl von Vereinen u.a. Fußball, Handball, Tennis, Eishockey oder Feuerwehren."></About>
        </div>
    );
}

export default Karriere;