import {React, useState} from 'react';
import {SliderData} from '../data/SliderData';
import useInterval from 'react-useinterval';
import { Link} from 'react-scroll';

const ImageSlider = ({slides, btn}) => {

    const [current, setCurrent] = useState(0);

    const nextSlide = () => {
        setCurrent(current === (slides.length -1) ? 0 : current + 1)
    }

    useInterval(nextSlide, 5000);

    return (
        <section className="flex" id="slider" >
            {SliderData.map((slide, index) => {
                return (
                    <div className={index === current ? 'slide slide-active absolute' : 'slide absolute'} key={index}>
                           <div key={index} style={{background: `no-repeat center center url(${slide.url})`}} />
                    </div>
                )
            })}
            <div className="greyscalebox"></div>
            <div className="flex container relative">
                <div className="hero-container flex-grow">
                    <div className="flex-grow hero-mobile">
                        <div className="hero-column c1 relative" >
                            <h1 className="white-text" data-aos="fade-up" >Sportplatz <span>&</span><br></br>Plakatwerbung</h1>
                            <p className="white-text" data-aos="fade-up" data-aos-delay="200">Wir machen lokalen Vereinssport möglich. Mit uns landen sie immer einen Volltreffer!</p>
                            <Link className="btn"
                                to="steps"
                                spy={true}
                                smooth={true}
                                duration={400} 
                                data-aos="fade-up" 
                                data-aos-delay="300"
                            >mehr Erfahren</Link>
                        </div>
                        <div className="hero-column c2 relative">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}   

export default ImageSlider;