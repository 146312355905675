export const SliderData = [
    {
        url: '/img/tennis.jpg',
        description: "SPH Tennis Feld Banner Werbung"
    },
    {
        url: '/img/basketball.jpg',
        description: "SPH Basketball Feld Banner Werbung"
    },
    {
        url: '/img/soccer.jpg',
        description: "SPH Fussball Feld Banner Werbung"
    },
];