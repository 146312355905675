

const Werbemittel = (props) => {
    return (
        <section id="werbemittel">
            <div className="container flex hero-mobile">
                <div className="c1 hero-column" data-aos="fade-right" data-aos-delay="50">
                    <h4 className="color-header">MARKETING</h4>
                    <h1 className="big-header white-text">Werbemittel</h1>
                    <p className="white-text">Aktuell nutzen wir Informationskästen, Spielankündigungsplakate und Billboards als Werbemittel.</p>
                </div>
                <div className="c2 hero-column" data-aos="fade-left" data-aos-delay="50">
                    <img alt="Werbemittel icon" src="/img/werbemittel.png"></img>
                </div>
            </div>
        </section>
    );
}

export default Werbemittel;