
const Steps = ({bgColor}) => {
    return (
        <section id="steps" className={bgColor === "white" ? "bg-white" : "bg-black"}>
            <div className="container flex flex-column">
                <h1 className={bgColor ==="white" ? "m-header black-text" : "m-header white-text"} data-aos="fade-up" data-aos-delay="0">So Funktioniert's</h1 >
                <h3 className={bgColor ==="white" ? "s-header black-text" : "s-header white-text"} data-aos="fade-up" data-aos-delay="100">Seit über 50 Jahren vermakrten wir mehr als 6000 lokale Vereine.</h3>
                <div className="step-item-container" data-aos="fade-up" data-aos-delay="200">
                    <div className={bgColor === "white" ? "step-item step-shadow" : "step-item"} >
                        <div>
                            <span></span>
                            <img alt="email icon" src="/img/icons/email.png"></img>
                        </div>
                        <h3>01 Schritt</h3>
                        <p>Nehmen Sie mit uns entweder über Telefon oder Email Kontakt auf.</p>
                    </div>
                    <div className={bgColor === "white" ? "step-item step-shadow" : "step-item"}>
                        <div>
                            <img alt="contract icon" src="/img/icons/contract.png"></img>
                            <span></span>
                        </div>
                        <h3>02 Schritt</h3>
                        <p>Wir erstellen eine Vermarktungs- vereinbarung für Sie.</p>
                    </div>
                    <div className={bgColor === "white" ? "step-item step-shadow" : "step-item"}>
                        <div>
                            <img alt="info icon" src="/img/icons/info.png"></img>
                            <span></span>
                        </div>
                        <h3>03 Schritt</h3>
                        <p>Wir stellen Ihnen einen Informationskasten oder ein anderes Marketing-Tool kostenfrei zur Verfügung.</p>
                    </div>
                    <div className={bgColor === "white" ? "step-item step-shadow" : "step-item"}>
                        <div>
                            <img alt="megaphone icon" src="/img/icons/megaphone.png" ></img>
                            <span></span>
                        </div>        
                        <h3>04 Schritt</h3>
                        <p>Wir kümmern uns um Vermarktung, Abwicklung & Druck der Werbeflächen.</p>
                    </div>
                    <div className={bgColor === "white" ? "step-item step-shadow" : "step-item"}>
                        <div>
                            <img alt="geldbörse icon" src="/img/icons/wallet.png"></img>
                            <span></span>
                        </div>
                        <h3>05 Schritt</h3>
                        <p>Auszahlung der finanziellen Zuwendungen an Ihren Verein.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}   

export default Steps;