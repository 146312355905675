import React from "react";
import { NavLink } from "react-router-dom";

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileNavOpen: false,
      setHeight: false,
    };
  }

  toggle = () =>
    this.setState({
      mobileNavOpen: !this.state.mobileNavOpen,
      setHeight: !this.state.setHeight,
    });

  render() {
    return (
      <div id="header" className={this.state.mobileNavOpen ? "bg-black" : ""}>
        <div className="container">
          <a href="/" id="logo-nav">
            <img alt="sph logo" src="/img/sph.png" id="logo"></img>
          </a>
          <div id="top-nav-container">
            <nav id="top-nav">
              <ul id="top-menu">
                <NavLink
                  to="/"
                  className="menu-item"
                  activeClassName="active"
                  exact
                >
                  <span className="menu-item-text">Willkommen</span>
                </NavLink>
                <NavLink
                  to="/vereine"
                  className="menu-item"
                  activeClassName="active"
                >
                  <span className="menu-item-text">Vereine</span>
                </NavLink>
                <NavLink
                  to="/sponsoren"
                  className="menu-item"
                  activeClassName="active"
                >
                  <span className="menu-item-text">Sponsoren</span>
                </NavLink>
                <NavLink
                  to="/karriere"
                  className="menu-item"
                  activeClassName="active"
                >
                  <span className="menu-item-text">Karriere</span>
                </NavLink>
                <a href="tel:06172 59070" className="menu-item">
                  <span className="menu-item-text2 phone-container">
                    <img alt="telefon icon" id="menu-call-icon" src="/img/icons/call.svg" width="15" />
                    06172 59070
                  </span>
                </a>
                <NavLink to="/kontakt">
                  <span className="menu-item-text2 primary-btn" style={{columnGap: "10px"}}>
                    <img alt="pfeil icon" id="menu-call-icon" src="/img/icons/right-arrow.svg" width="15" />
                    Kontakt
                  </span>
                </NavLink>
              </ul>
            </nav>
            <div id="mobile-nav" style={{ display: "none" }}>
              <button
                id="mobile-toggle"
                className={
                  this.state.mobileNavOpen
                    ? "mobile-nav-open"
                    : "mobile-nav-closed"
                }
                onClick={this.toggle}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.setHeight
              ? "set-height-auto mobile-menu-container"
              : "set-height-null mobile-menu-container"
          }
        >
          <ul id="mobile-menu">
            <li className="mobile-menu-item">
              <a href="/">Willkommen</a>
            </li>
            <li className="mobile-menu-item">
              <a href="/vereine">Vereine.</a>
            </li>
            <li className="mobile-menu-item">
              <a href="/sponsoren">Sponsoren</a>
            </li>
            <li className="mobile-menu-item">
              <a href="/karriere">Karriere</a>
            </li>
            <li className="mobile-menu-item">
              <a href="tel:06172 59070">Jetzt Anrufen</a>
            </li>
            <li className="mobile-menu-item">
              <a href="/kontakt">Kontakt</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
