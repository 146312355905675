import { Link} from 'react-scroll';
import Mailchimp from 'react-mailchimp-form';



const Footer = (props) => {
    return (
        <section id="footer" className="flex">
            <div className="container flex flex-column">
                <div id="copyright">
                    <ul className="flex flex-row footer-table">
                        <li>
                            <ul>
                                <li><Link className="backlink" duration={800} to="header" smooth={true} spy={true}>
                                    <img alt="sph logo" src="/img/sph-black.png"></img>
                                    </Link>
                                    <p>Sportplatz- & Plakatwerbung</p>
                                </li>
                            </ul>
                        </li>
                        
                        <li>
                            <ul className="footer-m">
                                <li><p>Max-Planck-Straße 9<br></br>61381 Friedrichsdorf</p></li>
                                <li><p>Telefon: <a href="tel:0617259070">+49 (0)6172 59070</a><br></br>Fax: +49 (0) 6172 590720</p></li>
                                <li><p>Email: <a href="mailto:sph@sport-info.de">sph@sport-info.de</a></p></li>
                            </ul>
                        </li>
                        <li>
                            <ul className="footer-r">
                                <li><h3>Abonnieren Sie unseren Newsletter</h3></li>
                                <li className="newsletter-box">
                                    <Mailchimp  
                                        action={process.env.REACT_APP_MAILCHIMP_URL} 
                                        fields={[
                                            {
                                                name: 'EMAIL',
                                                placeholder: 'Email-Adresse',
                                                type: 'email',
                                                required: true
                                            }
                                        ]}
                                        messages = {
                                            {
                                              sending: "",
                                              success: "Danke für's Abonnieren!",
                                              error: "Es ist ein unerwarteter Fehler aufgetreten",
                                              empty: "Du musst eine Email angeben",
                                              duplicate: "Diese Email ist bereits vorhanden",
                                              button: "Anmelden"
                                            }
                                        }
                                    />
                                </li>
                                <li>
                                    <ul className="flex flex-row footer-r-nav">
                                        <li><a href="/impressum">Impressum</a></li>
                                        <li><a href="/datenschutz">Datenschutz</a></li>
                                        <li><a href="/kontakt">Kontakt</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h5>Social Links</h5>
                                    <ul className="flex flex-row footer-r-links">
                                        <li><a href="https://www.facebook.com/SPH-Media-GmbH-102260608996919"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a></li>
                                        <li><a href=" https://instagram.com/sph_media_gmbh?igshid=YmMyMTA2M2Y"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a></li>
                                        <li><a href="https://www.kununu.com/de/sph-media/kommentare"><svg className="kununu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M314.3 429.8c10.06 14.53 6.438 34.47-8.094 44.53c-5.562 3.844-11.91 5.688-18.19 5.688c-10.16 0-20.12-4.812-26.34-13.78L128.1 273.3L64 338.9v109.1c0 17.67-14.31 32-32 32s-32-14.33-32-32v-384C0 46.34 14.31 32.01 32 32.01S64 46.34 64 64.01v183.3l201.1-205.7c12.31-12.61 32.63-12.86 45.25-.5c12.62 12.34 12.88 32.61 .5 45.25l-137.2 140.3L314.3 429.8z" /></svg></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>Copyright © SPH Media GmbH - 2021 All rights reserved</p>
                </div>
            </div>
        </section>
    );
}

export default Footer;







