
import Hero from '../components/Hero';
import Steps from '../components/Steps';
import Werbemittel from '../components/Werbemittel';
import Sponsoren from '../components/Sponsoren';
import Testimonials from '../components/Testimonials';
import Helmet from 'react-helmet';
import KontaktSection from '../components/KontaktSection';



const Vereine = (props) => {
    return (
        <div>
            <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="Vereinswerbung, Vereinssponsoring, Plakatwerbung, Sportplatzwerbung, Unterstützung" />
                <title>Vereine - Wir machen lokalen Vereinssport möglich</title>
                <meta name="description" content="Sie benötigen Vereinsunterstützung? Seit über 50 Jahren vermarkten wir mehr als 6000 lokale Vereine. Hierbei nutzen wir Informationskästen, Spielankündigungsplakate und Billboards als Werbemittel."/>
            </Helmet>
            <Hero src={'/img/vereine.png'} title="Wir machen Ihren " filler="Verein" title2="stark." text="Seit über 50 Jahren vermarkten wir mehr als 6000 lokale Vereine." goTo="steps"></Hero>
            <Steps bgColor="white"></Steps>
            <Werbemittel></Werbemittel>
            <section id="sorglos">
                <div className="container flex">
                    <div className="c2 hero-column">
                        <img alt="sorglos icon" src="/img/sorglospaket.png"></img>
                    </div>
                    <div className="c1 hero-column">
                        <h4 className="color-header">PARTNER WERDEN</h4>
                        <h1 className="big-header black-text">Das Rundum-Sorglos-Paket für Ihren Verein</h1>
                        <p className="black-text">Wir sind Ihr Partner wenn es um die fintanzielle Förderung Ihres Vereins geht! <br></br><br></br>Wir wissen wie schwierig es ist Vereine und soziale Einrichtungen erfolgreich zu führen. Neben dem Engagement der Mitglieder und Vorstände sind auch finanzielle Zuwendungen von Nöten. Als erfahrender Partner im Bereich der Out-of-Home Media für Vereine und Sportstätten können wir Ihrem Verein das Rundum-Sorglos-Paket für die finanzielle Förderung anbieten. Wir kümmern uns um die Bereitstellung der Werbeflächen, die Vermarktung an Sponsoren, den Druck und die Abwicklung.</p> 
                    </div>
                </div>
            </section>
            <Sponsoren></Sponsoren>
            <Testimonials></Testimonials>
            <KontaktSection></KontaktSection>
        </div>
    );
}

export default Vereine;