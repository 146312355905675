
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import GA4React from "ga-4-react";

const ga4react = new GA4React('G-MVCK3R2LPM');

(async () => {
  try {
    await ga4react.initialize();
  } catch (e) {}
})();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
