import React,{useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './pages/Landing';
import Vereine from './pages/Vereine';
import Sponsoren from './pages/Sponsoren';
import Karriere from './pages/Karriere';
import Kontakt from './pages/Kontakt';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Error from './pages/Error';
import Footer from './components/Footer';
import Menu from './components/Menu';
import ScrollToTop from './utils/ScrollToTop'
import './assets/css/App.scss';
import {Helmet} from 'react-helmet'
import AOS from 'aos';
import 'aos/dist/aos.css'



const App = () => {

  useEffect(() => {
    AOS.init({
      delay: 100
    })
  });

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta name="googlebot" content="index, follow"></meta>
      </Helmet>
      <ScrollToTop />
      <Menu></Menu>
        <Switch>
          <Route path="/" component={Landing} exact/>
          <Route path="/vereine" component={Vereine} />
          <Route path="/sponsoren" component={Sponsoren}  />
          <Route path="/karriere" component={Karriere} />
          <Route path="/kontakt" component={Kontakt} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={Datenschutz} />
          <Route component={Error} status={404} />
        </Switch>
        <Footer></Footer>
    </div>
  );
}

export default App;
