
const Error = (props) => {
    return (
        <section className="error-page">
            <div className="container">
                <div className="flex flex-column error-container">
                    <h2 className="white-text">404<br></br>Tut uns Leid da ist wohl etwas schief gelaufen</h2>
                    <a href="/" className="btn">Startseite</a>
                </div>
            </div>     
        </section>
    );
}

export default Error;