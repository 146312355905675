import KontaktForm from './KontaktForm';

const KontaktSection = (props) => {

    return(
        <section id="kontakt">
            <div className="kontakt-container">
            <div className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none"><path fill="#1E1E20" fillOpacity="1" d="M0,128L48,122.7C96,117,192,107,288,96C384,85,480,75,576,74.7C672,75,768,85,864,128C960,171,1056,245,1152,250.7C1248,256,1344,192,1392,160L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
            </div>
            <div className="flex container">
                <div className="flex flex-column kontakt-col">
                    <h4 className="color-header" data-aos="fade-up" data-aos-delay="0">KONTAKT</h4>
                    <h1 className="big-header black-text" data-aos="fade-up" data-aos-delay="100">Sie haben ein Anliegen?</h1>
                    <p className="black-text" data-aos="fade-up" data-aos-delay="200">Wir sind jederzeit für Sie erreichbar.<br></br>Schicken Sie Ihre Fragen einfach per mail via Formular oder per Mail: <strong>sph@sport-info.de</strong><br></br><br></br>Oder rufen Sie uns doch gerne an: <br></br><strong>+49 (0) 6172 59070</strong></p>
                </div>
                <div className="kontakt-form-container flex flex-column">
                    <KontaktForm ></KontaktForm>
                </div>
            </div>
            </div>
        </section>
    )
}


export default KontaktSection;