import { useForm } from "react-hook-form";

const KontaktForm = (props) => {

    const { register, handleSubmit } = useForm();
    const onSubmit = data => {
        data.submit = "submit";

        fetch(`https://sport-info.de/contact.php`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => {
                if(data.status === 400) {
                    document.getElementById('contact-form-response').classList.add("form-results-error");
                } else {
                    document.getElementById('contact-form-response').classList.remove("form-results-error");
                }
                let html = "";
                data["messages"].forEach(message => {
                    html += "<li>" + message + "</li>";
                })
                document.getElementById('contact-form-response').innerHTML = html;
            })
            .catch(err => console.error("Error:", err));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-aos="fade-left" data-aos-delay="200"
        >
            <div className="form-group">
                <span>*</span><input type="text" placeholder="Vor- & Nachname" {...register("name")}></input>
            </div>
            <div className="form-group">
                <input type="text" placeholder="Telefonnummer" {...register("telefonnummer")}></input>
            </div>
            <div className="form-group">
                <span>*</span><input type="email" placeholder="Email" {...register("email")}></input>
            </div>
            <div className="form-group">
                <span>*</span><textarea rows="5" placeholder="Ihre Nachricht" {...register("nachricht")}></textarea>
            </div>
            <ul id="contact-form-response" className="form-results"></ul>
            <div className="form-group">
                <input type="submit" value="kontaktieren"/>
            </div>
        </form>
    );
}

export default KontaktForm;