
import '../assets/css/Landing.scss';
import Steps from '../components/Steps';
import ImageSlider from '../components/ImageSlider';
import {SliderData} from '../data/SliderData';
import About from '../components/About';
import Sponsoren from '../components/Sponsoren';
import Testimonials from '../components/Testimonials';
import { Helmet } from 'react-helmet';
import KontaktSection from '../components/KontaktSection';

const Landing = (props) => {

    return (
        <section id="main">
            <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="Friedrichsdorf, Plakatwerbung, Vereinsunterstützung, Sponsoring, Frankfurt" />
                <title>SPH - Sportplatz & Plakatwerbung aus Friedrichsdorf</title>
                <meta name="description" content="SPH ist eine Sportplatz und Plakatwerbefirma aus der Nähe von Frankfurt am Main. Wir bieten lokales Vereinssponsoring und finanzielle Vereinsunterstützung seit mehr als 50 Jahren."/>
            </Helmet>
            <ImageSlider slides={SliderData}></ImageSlider>
            <Steps bgColor="black"></Steps>
            <About src="/img/about.jpg" title="Wer sind wir?" text="Bereits seit über 50 Jahren sind wir ein zuverlässiger Partner für Vereine, Sponsoren und Außendienstmitarbeiter und haben in dieser Zeit über 6000 Vereine in Deutschland vermarktet und damit finanziell unterstützt. Wir vermarkten bereits eine Vielzahl von Vereinen u.a. Fußball, Handball, Tennis, Eishockey oder Feuerwehren."></About>
            <Sponsoren />
            <Testimonials></Testimonials>
            <KontaktSection></KontaktSection>
        </section>
    );
}

export default Landing;