
import Helmet from 'react-helmet';
import KontaktForm from '../components/KontaktForm';


const Kontakt = (props) => {


    return (
        <div>
             <Helmet>
                <meta name="robots" content="follow"></meta>
                <meta name="keywords" content="kontakt, sph" />
                <title>Kontakt zu ihrer Vereinswerbeagentur</title>
                <meta name="description" content="Wir sind eine Sportplatz und Plakatwerbefirma. Unterstützen sie als Sponsor lokale Vereine & stärken sie das Image bei ihren Kunden. Jetzt SPH kontaktieren."/>
            </Helmet>
            <section id="hero" className="kontakt-container">
                <div className="container flex hero-mobile">
                    <div className="c1 hero-column">
                        <h4 className="color-header" data-aos="fade-up" data-aos-delay="0">KONTAKT</h4>
                        <h1 className="big-header white-text" data-aos="fade-up" data-aos-delay="100">Wir sind jederzeit erreichbar</h1>
                        <p className="white-text" data-aos="fade-up" data-aos-delay="200">Max-Planck-Straße<br></br>61381 Friedrichsdorf<br></br><br></br>Telefon: +49 (0)6172 59070<br></br>Fax: +49 (0)6172 590720<br></br><br></br>Email: sph@sport-info.de</p>
                    </div>
                    <div className="c2 hero-column">
                        <KontaktForm></KontaktForm>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kontakt;