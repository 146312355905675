

const Testimonials = (props) => {

    return(
        <section id="testimonials">
            <div className="testimonial-container">
                <div className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 320"><path fill="#1E1E20" fillOpacity="1" d="M0,256L48,234.7C96,213,192,171,288,128C384,85,480,43,576,74.7C672,107,768,213,864,229.3C960,245,1056,171,1152,165.3C1248,160,1344,224,1392,256L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                </div>
                <div className="flex container flex-column testimonial-text">
                    <h4 className="color-header" data-aos="fade-up" data-aos-delay="0">UNSERE KUNDEN</h4>
                    <h1 className="big-header black-text" data-aos="fade-up" data-aos-delay="100">Testimonials</h1>
                    <p className="black-text" data-aos="fade-up" data-aos-delay="200">Unsere Grundwerte sind das Herzstück von allem was wir tun. Sie sind in unser tägliches Arbeitsleben integriert und helfen uns dabei, dass unsere Kunden immer an erster Stelle stehen. Deshalb sollte das letzte Dankeschön immer von uns kommen.</p>
                    <div className="flex flex-row testimonial-col-container" data-aos="fade-up" data-aos-delay="300">
                        <div className="testimonial-square">
                            <h5>Thomas Hennings</h5>
                            <div>Vorsitzender des Vfl 07 Bremen e.V.</div>
                            <p>Die Zusammenarbeit mit der SPH Media GmbH verläuft seit 
                                Jahren harmonisch und sehr erfolgreich
                                mit unserem Fussballverein Vfl 07 
                                Bremen e.V. Die Qualität der 
                                Materialien und Spielplakate sind top 
                                sowie die vollumfängliche Betreuung.
                            </p>
                        </div>
                        <div className="testimonial-square">
                            <h5>Rodion Pauels</h5>
                            <div>Geschäftsführer / Sportlicher Leiter Kölner EC "Die Haie" e.V.</div>
                            <p>Bereits seit dem Jahr 2005 sind wir 
                                Partner von SPH Media GmbH. Die 
                                Zusammenarbeit war immer sehr 
                                angenehm. Wir freuen uns auf weitere 
                                Jahre der Partnerschaft.
                            </p>
                        </div>
                        <div className="testimonial-square">
                            <h5>Gabi Westphal</h5>
                            <div>Kassenwartin TSV Otterndorf</div>
                            <p>Seit 1995 ist unser Verein ein Partner 
                                der SPH. Die Abwicklung hat immer 
                                reibungslos funktioniert.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Testimonials;