
const Text = ({title, textContent}) => {
   
    return (
        <section className="basic-info flex">
            <div className="flex flex-column">
                <h2 className="black-text">{title}</h2>
                <p className="black-text">{textContent}</p>
            </div>
        </section>
    )
    
}


export default  Text;