import { Link} from 'react-scroll';

const Hero = ({title, title2, text, filler, src, goTo}) => {
    return (
        <section id="hero" className="flex">
            <div className="flex flex-row container relative">
                <div className="hero-container flex-grow">
                    <div className="flex-grow hero-mobile">
                        <div className="hero-column c1 relative" data-aos="fade-right" data-aos-delay="0">
                            <h1 className="white-text">{title}<span>{filler}</span> {title2}</h1>
                            <p className="white-text">{text}</p>
                            <Link className="btn"
                                to={goTo}
                                spy={true}
                                smooth={true}
                                duration={400}
                            >mehr Erfahren</Link>
                        </div>
                        <div className="hero-column c2 relative" data-aos="fade-left" data-aos-delay="0">
                            <img alt="section bild" src={src}></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}   

export default Hero;